<template>
  <div class="errors">
    <component :is="errorComponent" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'

import { ErrorTypes } from '@/constants.js'
const AnnouncementFailedError = defineAsyncComponent(() => import('@/components/errors/AnnouncementFailedError.vue'))
const CommonError = defineAsyncComponent(() => import('@/components/errors/CommonError.vue'))
const ExpiredReturnError = defineAsyncComponent(() => import('@/components/errors/ExpiredReturnError.vue'))
const NotPossibleForCountryError = defineAsyncComponent(() => import('@/components/errors/NotPossibleForCountryError.vue'))
const NotAllowedError = defineAsyncComponent(() => import('@/components/errors/NotAllowedError.vue'))
const ShipmentNotFoundError = defineAsyncComponent(() => import('@/components/errors/ShipmentNotFoundError.vue'))
const WeightError = defineAsyncComponent(() => import('@/components/errors/WeightError.vue'))

const componentByType = {
  [ErrorTypes.ANNOUNCEMENT_FAILED]: AnnouncementFailedError,
  [ErrorTypes.NOT_ALLOWED]: NotAllowedError,
  [ErrorTypes.COUNTRY_NOT_SUPPORTED]: NotPossibleForCountryError,
  [ErrorTypes.EXPIRED_RETURN]: ExpiredReturnError,
  [ErrorTypes.PARCEL_NOT_FOUND]: ShipmentNotFoundError,
  [ErrorTypes.PARCEL_TOO_HEAVY]: WeightError,
}

export default {
  name: 'ErrorView',
  components: {
    AnnouncementFailedError,
    CommonError,
    ExpiredReturnError,
    NotPossibleForCountryError,
    ShipmentNotFoundError,
    NotAllowedError,
    WeightError,
  },
  ANNOUNCEMENT_FAILED: ErrorTypes.ANNOUNCEMENT_FAILED,
  COUNTRY_NOT_SUPPORTED: ErrorTypes.COUNTRY_NOT_SUPPORTED,
  EXPIRED_RETURN: ErrorTypes.EXPIRED_RETURN,
  PARCEL_NOT_FOUND: ErrorTypes.PARCEL_NOT_FOUND,
  PARCEL_TOO_HEAVY: ErrorTypes.PARCEL_TOO_HEAVY,
  computed: {
    ...mapState({
      errorType: (state) => state.errorType,
    }),
    errorComponent() {
      const loadedErrorComponent = componentByType[this.errorType]
      return loadedErrorComponent || CommonError
    },
  },
}
</script>
<style lang="scss" scoped>
.errors {
  display: flex;
  height: 100%;
}
</style>
